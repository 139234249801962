.rooms {
  padding: 14px;
  width: calc(100% - 28px);
  height: calc(100% - 28px);
  background-color: #131a2f;
}

.rooms-header {
  position: relative;
  top: 14px;
  font-weight: bold;
  font-size: 1.5em;
  color: #fff;
}

.roomsList {
  position: relative;
  overflow: scroll;
  top: 30px;
  height: calc(100% - 95px);
}

.room {
  padding-bottom: 20px;
  color: #ede6e0;
}

.room-item {
  display: flex;
}

.room-name {
  width: 100%;
  margin-left: 14px;
  font-weight: bold;
}

.participant {
  padding-top: 10px;
  padding-left: 50px;
  color: #fff;
}

.rooms-input {
  position: absolute;
  bottom: 14px;
  width: calc(100% - 110px);
  height: 25px;
}

.create-room-button {
  position: absolute;
  bottom: 14px;
  right: 14px;
  width: 60px;
  height: 31px;
  font-weight: bold;
}
