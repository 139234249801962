.chat {
  width: 100%;
  height: calc(100% - 40px);
}

.chat-header {
  position: relative;
  top: 14px;
  font-weight: bold;
  font-size: 1.5em;
  color: #fff;
}

.messageList {
  position: relative;
  overflow: scroll;
  top: 30px;
  height: calc(100% - 60px);
}

.message {
  padding: 5px 0;
  color: #fff;
}

.chat-input {
  position: absolute;
  bottom: 14px;
  width: calc(100% - 100px);
  height: 25px;
}

.send-chat-button {
  position: absolute;
  bottom: 14px;
  right: 14px;
  width: 50px;
  height: 31px;
  font-weight: bold;
}
