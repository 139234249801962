.app {
  background-color: #06264d;
  position: absolute;
  height: 100%;
  width: 100%;
}

.main-container {
  width: 100%;
  height: 100%;
}

#socket-id {
  position: absolute;
  background-color: white;
  z-index: 10;
  bottom: 20px;
  right: 60px;
}

.rooms-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 60px;
  width: 350px;
}

button {
  background-color: #131a2f;
  border-color: #fff;
  color: #fff;
  border-radius: 10px;
}

input {
  background-color: #131a2f;
  border-color: #fff;
  color: #fff;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.disabled {
  pointer-events: none;
}

.hidden {
  visibility: hidden;
}
