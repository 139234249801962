.tray {
  flex: none;
  position: absolute;
  width: 100%;
  height: 60px;
  background-color: #131a2f;
  display: flex;
  bottom: 0;
  align-items: center;
}
