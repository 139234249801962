.game-button {
  position: absolute;
  width: 60px;
  height: 30px;
  top: 14px;
  left: 14px;
  background-color: #131a2f;
  border-color: #fff;
  color: #fff;
  border-radius: 10px;
}

.game-tutorial {
  width: 30px;
  left: 85px;
}

.game-url-input {
  position: absolute;
  top: 14px;
  left: 125px;
  width: 300px;
  height: 25px;
  background-color: #131a2f;
  border-color: #fff;
  color: #fff;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.game-host {
  left: 450px;
}

.game-invite {
  left: 520px;
}

.games-container {
  position: absolute;
  bottom: 200px;
  left: 14px;
  width: calc(100% - 440px);
  height: calc(100% - 305px);
  background-color: #131a2f;
  border-radius: 20px;
  padding: 20px;
}

.game {
  position: absolute;
  bottom: 200px;
  left: 14px;
  width: calc(100% - 400px);
  height: calc(100% - 265px);
  background-color: #131a2f;
  border-radius: 4px;
}

.game-tutorial-container {
  position: absolute;
  top: calc(50% - 250px);
  left: calc(50% - 400px);
  width: 400px;
  height: 300px;
  background-color: #06264d;
  border-radius: 20px;
  border-color: #fff;
  border-style: solid;
  padding: 20px;
}
