.tutorial {
  display: block;
  width: 100%;
  height: 100%;
}

.tutorial-text {
  position: absolute;
  top: 100px;
  left: 15%;
  color: #ede6e0;
  width: 70%;
  height: 50%;
  font-weight: bold;
}

.tutorial-buttons {
  display: flex;
  position: absolute;
  width: calc(100% - 40px);
  bottom: 100px;
}

.tutorial-buttons button {
  width: 60px;
  height: 30px;
  border: none;
  outline: none;
  background-color: #eb5953;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.tutorial-prev {
  margin-left: auto;
  margin-right: 50px;
}

.tutorial-next {
  margin-right: auto;
}

.tutorial-pages {
  position: absolute;
  bottom: 50px;
  left: 35%;
  color: #ede6e0;
  width: 30%;
  height: auto;
}

.tutorial-close {
  position: absolute;
  top: 14px;
  right: 0;
  width: 30px;
  height: 30px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}
