.error-modal {
  position: absolute;
  top: calc(50% - 135px);
  left: calc(50% - 150px);
  width: 300px;
  height: 150px;
  border-radius: 10px;
  background-color: #131a2f;
  padding: 14px;
  color: #fff;
}
.error-modal span {
  position: relative;
  top: 30px;
  left: 30px;
  color: #fff;
}
