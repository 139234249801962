.login {
  position: absolute;
  top: calc(50% - 135px);
  left: calc(50% - 150px);
  width: 300px;
  height: 150px;
  border-radius: 10px;
  background-color: #131a2f;
  padding: 14px;
  color: #fff;
}
.login span {
  position: relative;
  top: 30px;
  left: 30px;
  color: #fff;
}

.login button {
  position: relative;
  top: 90px;
  left: calc(50% - 30px);
  width: 60px;
  height: 30px;
  border: none;
  outline: none;
  background-color: #eb5953;
  color: #fff;
  border-radius: 10px;
}
