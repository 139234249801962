.tutorial-pages-list {
  display: flex;
  width: 100%;
  height: 100%;
}

.tutorial-page {
  margin: auto;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 5px;
}

.selected {
  background-color: #eb5953;
}
