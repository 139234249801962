.sidebar {
  position: absolute;
  right: 0;
  bottom: 60px;
  width: 350px;
  height: calc(100vh - 60px);
  background-color: #131a2f;
}

.sidebar-close {
  position: absolute;
  top: 14px;
  right: 0;
  width: 30px;
  height: 30px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.sidebar-tabs {
  position: relative;
  top: 14px;
  padding: 0 14px;
  overflow: hidden;
  background-color: inherit;
}

.sidebar-tabs button {
  background-color: inherit;
  color: #fff;
  border-radius: 10px;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.sidebar-tabs button.active {
  background-color: #eb5953;
  color: #fff;
}

.sidebar-tabs button:hover {
  /*background-color: #ddd;*/
}

.sidebar-content {
  position: relative;
  width: calc(100% - 28px);
  height: calc(100% - 28px);
  padding: 14px;
}
