.games {
  width: 100%;
  height: 100%;
}

.games-header {
  display: flex;
  top: 14px;
  justify-content: center;
  margin: auto;
  width: 100px;
  font-weight: bold;
  font-size: 1.5em;
  color: #fff;
}

.gamesList {
  position: relative;
  overflow: scroll;
  top: 30px;
  height: calc(100% - 60px);
}

.game-item {
  display: flex;
  padding: 14px 0;
}

.game-name {
  color: #ede6e0;
  width: calc(30% - 25px);
  font-weight: bold;
}

.game-desc {
  color: #ede6e0;
  width: calc(70% - 25px);
}

.game-play {
  top: 75px;
  left: calc(50% - 30px);
  width: 60px;
  height: 30px;
  border: none;
  outline: none;
  background-color: #eb5953;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}
